import React, { useState } from "react";
import Header from "components/Layouts/Header";
import { useRouter } from "next/router";
import DarkFooter from "components/Layouts/DarkFooter";
import Head from "next/head";

export default function Page({
  children,
  title = "Next-level checkouts for online businesses",
  description = "CheckoutJoy is a checkout and e-commerce platform that helps businesses sell anything online and accept payments with your local payment methods.",
  meta = null,
  mainClasses = '',
  ogImageUrl = "https://assets.checkoutjoy.com/og/og.png",
  ogImageAlt = "Sell anything online and accept payments with your local payment methods.",
}) {
  const router = useRouter();

  const [isHome] = useState(router.pathname === "/");
  return (
    <div className="relative bg-white overflow-hidden">
      <Head>
        <title>{title}</title>
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <meta name="description" content={description} />
        <meta property="fb:app_id" content={"352507272440982"} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={"CheckoutJoy"} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />

        <meta property="og:image" content={ogImageUrl} />
        <meta property="og:image:alt" content={ogImageAlt} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="940" />
        <meta property="og:image:height" content="788" />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@checkoutjoy" />
        <meta name="twitter:image" content="ogImageUrl" />
        {meta}
      </Head>

      <div className="relative pt-6">
        <Header />

        <main
          className={
            mainClasses ||
            "mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24"
          }
        >
          {children}
        </main>
      </div>
      <DarkFooter />
    </div>
  );
}
