export const testimonials = [
  [
    {
      content:
        "CheckoutJoy has been absolutely brilliant in helping me set up to charge my South African customers in Rands, from platforms that only offer Dollars or Euros. Meiring has offered excellent personal service, and loads of great technical advice. He helped me set up my products and memberships in five minutes! I highly recommend this amazing tool, thats so simple and also works so well without any complicated fiddling from me!",
      author: {
        name: "Linda Hennings",
        role: "forgingahead.co.za",
        image:
          "https://content.app-sources.com/s/50121845964110051/uploads/Images/FA_Logo_Colour-0841897.svg",
      },
    },

    {
      content:
        "Really loved the tool and the support team. Well recommended by Thinkific. Good tool to give & manage Multiple payment Options for Landing pages in one dashboard.",
      author: {
        name: "Davzon",
        role: "Davzon Pvt. Ltd.",
        image: "https://assets.checkoutjoy.com/icons/dav.jpg",
      },
    },
  ],
  [
    {
      content:
        "CheckoutJoy saved the day. I was a very stressed out technically challenged business owner trying to figure out how to make payment possible in my currency ZAR and with a local bank in South Africa. The solution was CheckoutJoy. From the user friendly tutorial to a free 15 min call. Meiring was helpful, calm and reassuring. He made the process feel seamless. Thank you CheckoutJoy, excited for launch day and to have found this local business",
      author: {
        name: "Kearin-Ashley Heward",
        role: "The Sweetest Slumber",
        image: "https://assets.checkoutjoy.com/icons/slumber.png",
      },
    },
  ],
  [
    {
      content:
        "Awesome software!☄️highly recommend. Great customer service, seamless integration and multiple payment gateways makes this an awesome piece of software! Well done to the CheckoutJoy team!",
      author: {
        name: "Christiaan Blom",
        role: "Builderrr",
        image: "https://assets.checkoutjoy.com/site/c-headshot.jpg",
      },
    },
    {
      content:
        "Checkoutjoy is beautifully designed and super easy to set up and use. Support is top class - friendly, prompt, and helpful. Integrates perfectly with my Kartra setup. Fantastic.",
      author: {
        name: "Keith",
        role: "Eslbase.com",
        image: "https://assets.checkoutjoy.com/site/k-headshot-1.jpg",
      },
    },
  ],
];

function QuoteIcon(props) {
  return (
    <svg aria-hidden="true" width={105} height={78} {...props}>
      <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
    </svg>
  );
}

export function Testimonials() {
  return (
    <section
      id="testimonials"
      aria-label="What our customers are saying"
      className="bg-gray-100 py-12 sm:py-24"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="mb-8 mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            This is what our loyal customers have to say
          </h2>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {testimonials.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-6 sm:gap-y-8">
                {column.map((testimonial, testimonialIndex) => (
                  <li key={testimonialIndex}>
                    <figure className="relative rounded-2xl bg-white p-6 shadow-xl border">
                      <QuoteIcon className="absolute top-6 left-6 fill-slate-100" />
                      <blockquote className="relative">
                        <p className="text-lg tracking-tight text-slate-900">
                          {testimonial.content}
                        </p>
                      </blockquote>
                      <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                        <div>
                          <div className="font-display text-base text-slate-900">
                            {testimonial.author.name}
                          </div>
                          <div className="mt-1 text-sm text-slate-500">
                            {testimonial.author.role}
                          </div>
                        </div>
                        <div className="overflow-hidden rounded-full bg-slate-50">
                          <img
                            className="h-14 w-14 "
                            src={testimonial.author.image}
                            alt=""
                          />
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
