import React from "react";
import { CheckIcon } from "@heroicons/react/solid";

const defaultFeatures = [
  {
    name: "Invoices",
    description: (
      <>Automatically generate and send compliant invoices for your sales.</>
    ),
  },
  {
    name: "Sales Tax",
    description:
      "Collect the correct VAT and sales tax for any customer around the world.",
  },
  {
    name: "Memberships & Subscriptions",
    description:
      "Accept subscription payments and manage them from our dashboard.",
  },
  {
    name: "Setup Fees & Free Trials",
    description:
      "Add additional options to your pricing like setup fees and free trials.",
  },
  {
    name: "Multiple Currencies",
    description:
      "Automatically convert your cart to your customer's local currency.",
  },
  {
    name: "Order Bumps",
    description:
      "Increase your cart value by offering related products and promotions.",
  },
  {
    name: "Coupons",
    description: "Offer promotions and coupons.",
  },
];

export default function Features({
  className = "",
  superHeading = "Everything you need",
  heading = "Your entire checkout stack under one roof",
  subheading = " Accept payments from anywhere in the world. We support all major payment methods and currencies.",
  features = defaultFeatures,
}) {
  return (
    <div className={`py-24 ${className}`}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-indigo-600">
              {superHeading}
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {heading}
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              {subheading}
            </p>
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="font-semibold text-gray-900">
                  <CheckIcon
                    className="absolute left-0 top-1 h-5 w-5 text-indigo-500"
                    aria-hidden="true"
                  />
                  {feature.name}
                </dt>
                <dd className="mt-2">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
