import React from "react";
import clsx from "clsx";

const platforms = [
  {
    image:
      "https://www.adyen.com/dam/jcr:0a31057a-c8e6-48b3-86eb-75862ef2c923/adyen-header-logo-green.svg",
    alt: "Adyen",
    link: "/processors",
    classNames: "h-10 md:h-16",
  },
  {
    image: "https://assets.checkoutjoy.com/stripe.png",
    alt: "Stripe",
    link: "/payment-processors/",
    classNames: "h-10 md:h-16",
  },
  {
    image:
      "https://www.instamojo.com/wp-content/uploads/2020/05/logo_instamojo.png",
    alt: "instamojo payment options",
    link: "/payment-processors/",
    classNames: "h-8 md:h-10",
  },
  {
    image: "https://assets.checkoutjoy.com/mollie-logo-dark.png",
    alt: "mollie payment options",
    link: "/payment-processors/",
    classNames: "h-8 md:h-10",
  },
  {
    image: "https://assets.checkoutjoy.com/icons/klarna-icon-pink.svg",
    alt: "klarna payment options",
    link: "/payment-processors/",
    classNames: "h-8 md:h-10",
  },
  {
    image: "https://razorpay.com/assets/razorpay-logo.svg",
    alt: "razorpay payment options",
    link: "/payment-processors/",
    classNames: "h-10 md:h-12",
  },
  {
    image:
      "https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png",
    alt: "paypal payment options",
    link: "/payment-processors/",
    classNames: "h-8 md:h-10",
  },
  {
    image:
      "https://assets.checkoutjoy.com/payment-icons/paystack/paystack-logo.png",
    alt: "framer payment options",
    link: "/payment-processors/",
    classNames: "h-8 md:h-10",
  },
  {
    image:
      "https://www.xendit.co/wp-content/uploads/2020/03/XENDIT-LOGOArtboard-1@2x-1024x441.png",
    alt: "xendit payment options",
    link: "/payment-processors/",
    classNames: "h-10 md:h-14",
  },
  {
    image: "https://assets.checkoutjoy.com/logos/coinbase.svg",
    alt: "coinbase",
    link: "/payment-processors/",
    classNames: "h-8",
  },
  {
    image: "https://assets.checkoutjoy.com/logos/payfast-new.svg",
    alt: "payfast",
    link: "/payment-processors/",
    classNames: "h-10 md:h-12",
  },
  {
    image: "https://assets.checkoutjoy.com/logos/mercado-pago-logo-white.png",
    alt: "mercado pago",
    link: "/payment-processors/",
    classNames: "h-10 md:h-12",
  },
  {
    image: "https://assets.checkoutjoy.com/logos/vipps.svg",
    alt: "vipps",
    link: "/payment-processors/",
    classNames: "h-8 md:h-10",
  },
];

export default function PaymentProcessors() {
  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="grid grid-cols-1 items-center gap-x-24 gap-y-16 lg:grid-cols-2">
        <div className="md:order-2 mx-auto w-full max-w-xl lg:mx-0">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
            Connect your local payment processor
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-500">
            Add any of our supported payment options to your checkouts. Let your
            customers pay using payment options that they know and trust.
          </p>
          <div className="mt-6">
            <a
              href="/payment-processors/"
              className="inline-flex items-center sm:px-4 py-2 border border-transparent bg-transparent text-base font-medium rounded-md text-indigo-600 hover:text-indigo-700"
            >
              See all supported processors &rarr;{" "}
            </a>
          </div>
        </div>
        <div>
          <div className="md:order-1 mx-auto grid w-full max-w-xl grid-cols-2 items-center gap-y-8 lg:mx-0 lg:max-w-none lg:pl-8 gap-x-6">
            {platforms.map((platform, index) => {
              return (
                <a
                  href={platform.link}
                  target="_blank"
                  className="flex items-center justify-center"
                >
                  <img
                    key={index}
                    className={clsx(
                      platform.classNames,
                      "object-contain w-auto md:max-h-12",
                    )}
                    src={platform.image}
                    alt={platform.alt}
                    width={105}
                    height={48}
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export function PaymentProcessorsLogoCloud() {
  return (
    <div>
      <div className="md:order-1 mx-auto grid w-full max-w-xl grid-cols-2 items-center gap-y-8 lg:mx-0 lg:max-w-none lg:pl-8 gap-x-6">
        {platforms.map((platform, index) => {
          return (
            <a
              href={platform.link}
              target="_blank"
              className="flex items-center justify-center"
            >
              <img
                key={index}
                className={clsx(
                  platform.classNames,
                  "object-contain w-auto md:max-h-12",
                )}
                src={platform.image}
                alt={platform.alt}
                width={105}
                height={48}
              />
            </a>
          );
        })}
      </div>
    </div>
  );
}
